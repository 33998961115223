<template>
  <div :class="$style.root">
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}}</h1>
    <div class="mb-4 clearfix">
      <InstructionButton v-if="!!instructionLink.length" :link="instructionLink" />
      <VBtn v-if="isReviewer || isSpectator" class="float-right ml-2" href="/prequalification/" depressed color="default">
        Архив заявок
      </VBtn>
      <InstructionDialog />
      <VBtn v-if="isReviewer || isSpectator" class="float-right ml-2" @click="$router.push({ name: 'pkomain/RegistryListView'})" depressed color="primary">
        Реестр контрагентов
      </VBtn>
      <VBtn depressed @click="$router.push({ name: 'pko/PkoDashboardView'})">
        <VIcon v-text="'mdi-arrow-left'" />
        Назад
      </VBtn>
    </div>
    <div class="mb-0" v-if="isContragent && !!registryCurrent && !!registryCurrent.length">
      <VRow>
        <VCol cols-md="12" md="6" v-for="({company, status, date}, index) in registryCurrent" :key="index">
          <VAlert v-bind="{...registryIconColorControl(status)}" class="py-2 my-0" style="height: 100%" dark dense>
            <b>{{ status.name }}</b>
            <span v-if="date"> до {{ date }} </span>
            на {{ company }}
          </VAlert>
        </VCol>
      </VRow>
    </div>
    <CounterpartyList
        :items="counterparties.items"
        :count="counterparties.count"
        :page="page"
        :size="size"
        :loading="loading"
    />
    <VFooter v-if="isContragent" app>
      <div class="py-3 grow">
        <CounterpartyDialog @success="onUpdateState" />
      </div>
    </VFooter>
  </div>
</template>

<script>
import {debounce, find, get} from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import { ROLES } from '@/store/user/enums';
import { STATUSES } from '@/store/pkomain/enums';
import CounterpartyList from '@/components/pkomain/CounterpartyList/CounterpartyList';
import CounterpartyDialog from '@/components/pkomain/CounterpartyDialog/CounterpartyDialog';
import InstructionButton from '@/components/pkomain/InstructionButton/InstructionButton';
import InstructionDialog from '@/components/pkomain/InstructionDialog/InstructionDialog';
export default {
  name: 'CounterpartyListView',
  components: {
    CounterpartyList,
    CounterpartyDialog,
    InstructionButton,
    InstructionDialog,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  computed: {
    ...mapGetters({
      loading: 'pkomain/pending',
      counterparties: 'pkomain/counterparties',
      hasRole: 'user/hasRole',
      registryCurrent: 'pkomain/registry',
      pkoList: 'pko/pkoList',
    }),
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'pkomain');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkomain');
    },
    isContragent() {
      return this.hasRole(ROLES.CONTRAGENT, 'pkomain');
    },
    instructionLink() {
      return get(find(get(this.pkoList, 'items', []), { link: '/pkomain/' }), 'instruction', '');
    },
  },
  methods: {
    ...mapActions({
      fetchCounterpartyList: 'pkomain/fetchCounterpartyList',
      fetchRegistryCurrent: 'pkomain/fetchRegistryCurrent',
      fetchPkoList: 'pko/fetchPkoList',
    }),
    onUpdateState: debounce(function() {
      const { page, size, filter } = this;
      this.fetchCounterpartyList({ page, size, filter });
      if (this.isContragent) this.fetchRegistryCurrent();
      this.fetchPkoList();
    }, 500),
    registryIconColorControl({code}) {
      let icon = 'mdi-progress-check';
      let color = 'warning';
      if (code === STATUSES.NOT_PASSED) {
        icon = 'mdi-alert-circle-outline';
        color = 'error';
      }
      if (code === STATUSES.PASSED) {
        icon = 'mdi-check-circle-outline';
        color = 'success';
      }
      return {icon, color};
    }
  },
  watch: {
    page: function() {
      this.onUpdateState();
    },
    size: function() {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function() {
        this.onUpdateState();
      }
    }
  },
  mounted() {
    this.onUpdateState();
  }
}
</script>

<style module lang="scss">
.root {}
</style>

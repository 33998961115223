<template>
  <div>
    <VBtn class="float-right ml-2" depressed color="primary lighten-1" @click="dialog = !dialog">
      ПКО – Самое важное!
    </VBtn>
    <VDialog v-model="dialog" max-width="1681" scrollable>
      <VCard tile>
        <VToolbar flat dark color="primary">
          <VToolbarTitle class="px-2">Предварительный квалификационный отбор. Самое важное!</VToolbarTitle>
          <VSpacer/>
          <VBtn icon dark @click="dialog = false">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VCardText class="pt-4">
          <VImg contain :aspect-ratio="1681 / 813" max-height="calc(100vh - 210px)" :src="url('/pkomain/assets/picsc.png')" />
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {ROLES} from '@/store/user/enums';

export default {
  name: 'InstructionDialog',
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    ...mapGetters({
      loading: 'pkomain/pending',
      counterparties: 'pkomain/counterparties',
      hasRole: 'user/hasRole',
    }),
    count() {
      return this.counterparties.count;
    },
    isContragent() {
      return this.hasRole(ROLES.CONTRAGENT, 'pkomain');
    },
  },
  methods: {
    url(href) {
      return process.env.VUE_APP_PUBLIC_PATH + href;
    },
  },
  watch: {
    loading(value) {
      if (!this.dialog) this.dialog = !value && !this.count && this.isContragent;
    }
  }
}
</script>

<style module lang="scss">

</style>

<template>
  <div :class="$style.root">
    <VDataTable
        :headers="[
          { text: 'ID', value: 'id', sortable: false },
          { text: 'ИНН', value: 'inn', sortable: false },
          { text: 'Организация', value: 'name', sortable: false },
          { text: 'Предприятие', value: 'company', sortable: false },
          { text: 'Статус', value: 'status', sortable: false },
        ]"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
          itemsPerPageText: '',
        }"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="count"
        :mobile-breakpoint="0"
        :item-class="rowClassControl"
        @click:row="({ id }) => $router.push({ name: 'pkomain/CounterpartyDetailView', params: { counterparty: id }})"
        @update:page="page => $router.push({ query: { ...$route.query, page } })"
        @update:items-per-page="size => $router.push({ query: { ...$route.query, size } })"
    >
      <template v-slot:body.prepend>
        <tr>
          <td v-for="key in ['id', 'inn', 'companyName', 'company']" :key="key">
            <VTextField
                dense
                clearable
                hide-details
                :value="$route.query[key]"
                @input="value => $router.push({ query: { ...$route.query, [key]: value } })"
            />
          </td>
          <td>
            <VSelect
                dense
                clearable
                hide-details
                :items="statuses"
                :value="$route.query['status']"
                @input="value => $router.push({ query: { ...$route.query, ['status']: value } })"
            />
          </td>
        </tr>
      </template>
      <template v-slot:item.control="{ item: { id, comments } }">
        <slot name="control" v-bind="{ id, comments }" />
      </template>
    </VDataTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ROLES } from '@/store/user/enums';
import { STATUSES } from '@/store/pkomain/enums';

export default {
  name: 'ContractList',
  props: {
    items: { type: Array },
    count: { type: Number },
    page: { type: Number },
    size: { type: Number },
    loading: { type: Boolean },
  },
  data: function() {
    return {
      options: {
        page: this.page,
        itemsPerPage: this.size
      },
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'pkomain');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkomain');
    },
    statuses() {
      return this.isReviewer || this.isSpectator
        ? [
            {text: 'Отклонено', value: STATUSES.NO},
            {text: 'Доработка', value: STATUSES.REWORK},
            {text: 'Согласовано', value: STATUSES.YES},
            {text: 'Ожидает решения', value: STATUSES.WAIT_FOR_DECISION},
          ]
        : [
          {text: 'На согласовании', value: STATUSES.ON_APPROVAL},
          {text: 'Не пройдено', value: STATUSES.PKO_NOT_PASSED},
          {text: 'Пройдено', value: STATUSES.PKO_PASSED},
          {text: 'Подготовка', value: STATUSES.PREPARE},
          {text: 'На доработке', value: STATUSES.ON_REWORK},
        ];
    }
  },
  methods: {
    rowClassControl({ archive }) {
      if(archive) return 'grey--text';
      return '';
    }
  }
}
</script>

<style module lang="scss">
.root {
  th {
    width: 200px !important;
    &:nth-child(1) {
      width: 1px !important;
    }
  }
  td {
    cursor: pointer;
  }
}
</style>

<template>
  <VBtn class="float-right ml-2" :href="link" target="_blank" depressed color="info lighten-1">
    Инструкция
  </VBtn>
</template>

<script>
export default {
  name: 'InstructionButton',
  props: {
    link: { type: String, default: '/upload/Инструкция_КА_ПКО.docx' },
  },
}
</script>

<style scoped>

</style>
